import { useEffect, useState, type FC } from 'react';
import { Outlet, useOutletContext } from 'react-router-dom';
import {
  ButtonBase,
  ContextMenu,
  ContextMenuItem,
  PageLayoutContainer,
  PageLayoutHeaderContainer,
  PageLayoutHeaderLeftContainer,
  PageLayoutHeaderMainContainer,
  PageLayoutHeaderMenuButton,
  PageLayoutHeaderTitleContainer,
  PageLayoutMainContainer,
  PageLayoutMenuHeaderMainContainer,
  PageLayoutSideMenu,
  ReferenceType,
  SideMenuItem,
  SideMenuItemExtended,
  SideMenuTopBar,
  SideMenuVariant,
  SystemIcon,
} from '@volvo/vce-uikit';
import { useWindowSize } from '../../helpers/hooks/useWindowSize';

const sites = [
  { name: 'Yalta', id: 'de25a213-0eb7-4233-9fe7-8f7146f9fd91' },
  { name: 'Kiruna', id: 'aa19aa74-f5e5-42ca-831d-0a655c0b84b2' },
  { name: 'Eskilstuna', id: 'e1c68635-88ee-4a45-aff7-48093cf5b42f' },
  { name: 'Arendal', id: 'f93837b0-543f-41dd-b358-1d031d04b038' },
  { name: 'No site map', id: 'af321c1b-4e1e-493c-b9d7-ef840914c9f5' },
];

type ContextType = { siteId: string };

export const PageLayout: FC = () => {
  const [reference, setReference] = useState<HTMLElement | null>(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(localStorage.getItem('isMenuOpen') === 'true');
  const [currentSite, setCurrentSite] = useState(
    sites.find(({ id }) => id === localStorage.getItem('siteId')) || sites[0],
  );

  useEffect(() => {
    localStorage.setItem('isMenuOpen', isMenuOpen.toString());
  }, [isMenuOpen]);

  useEffect(() => {
    localStorage.setItem('siteId', currentSite.id.toString());
  }, [currentSite]);

  const closeMenu = (e?: any) => {
    if (
      e?.target &&
      document.querySelectorAll('[data-floating-ui-portal]')?.[0]?.contains(e?.target as Element)
    ) {
      return;
    }
    setIsMenuOpen(false);
  };

  const { innerHeight } = useWindowSize();
  const height = `${innerHeight}px`;

  return (
    <PageLayoutContainer style={{ height, minHeight: 'initial' }}>
      <PageLayoutHeaderContainer sticky>
        <PageLayoutHeaderLeftContainer>
          <PageLayoutHeaderMenuButton show onClick={() => setIsMenuOpen((state) => !state)} />
          <PageLayoutHeaderTitleContainer>Site map</PageLayoutHeaderTitleContainer>
        </PageLayoutHeaderLeftContainer>
      </PageLayoutHeaderContainer>
      <PageLayoutMenuHeaderMainContainer>
        <PageLayoutSideMenu
          variant={SideMenuVariant.Extended}
          open={isMenuOpen}
          onClose={closeMenu}
        >
          <SideMenuTopBar
            minimizable={false}
            onToggle={() => setIsMenuOpen((isMenuOpen) => !isMenuOpen)}
            border
          >
            <ButtonBase
              ref={(ref) => setReference(ref)}
              onClick={() => setDropdownOpen(true)}
              style={{
                position: 'relative',
                padding: 8,
                margin: '2px -8px',
                display: 'flex',
                gap: 8,
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <SideMenuItemExtended
                icon="company-account"
                iconFilled
                label={currentSite.name}
                labelVariant="heading4"
              />
              <SystemIcon size={'16'} icon={dropdownOpen ? 'chevron-up' : 'chevron-down'} />
            </ButtonBase>
            <ContextMenu
              open={dropdownOpen}
              reference={reference}
              referenceType={ReferenceType.Anchor}
              onClose={() => setDropdownOpen(false)}
              placement="bottom-start"
              style={{ maxHeight: 300, width: 200, overflow: 'auto' }}
            >
              {sites.map((site) => {
                const selected = site.id === currentSite.id;
                return (
                  <ContextMenuItem
                    key={site.id}
                    open={selected}
                    icon={selected ? 'check' : undefined}
                    style={{
                      flexDirection: selected ? 'row-reverse' : 'row',
                      justifyContent: 'space-between',
                    }}
                    onClick={(e: any) => {
                      e.stopPropagation();
                      setCurrentSite(site);
                      setDropdownOpen(false);
                    }}
                  >
                    {site.name}
                  </ContextMenuItem>
                );
              })}
            </ContextMenu>
          </SideMenuTopBar>
          <SideMenuItem key="sitemap" children="Site map" href="/" icon="site" />
          <SideMenuItem
            key="production-data"
            children="Production data"
            href="/production-data"
            icon="factory"
          />
          <SideMenuItem
            key="deviations"
            children="Deviations"
            href="/deviations"
            icon="technical-error"
          />
          <SideMenuItem key="admin" children="Admin" href="/admin" icon="toolbox-settings" />
        </PageLayoutSideMenu>
        <PageLayoutHeaderMainContainer>
          <PageLayoutMainContainer>
            <Outlet context={{ siteId: currentSite.id } satisfies ContextType} />
          </PageLayoutMainContainer>
        </PageLayoutHeaderMainContainer>
      </PageLayoutMenuHeaderMainContainer>
    </PageLayoutContainer>
  );
};

export function useApp() {
  return useOutletContext<ContextType>();
}
